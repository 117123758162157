.unavailable {
  position: relative;
  overflow: hidden;

  & > * {
    filter: blur(4px);
  }

  &::before {
    content: "DESHABILITADO";
    position: absolute;
    inset: 0;
    margin: auto;
    background: linear-gradient(90deg, color(transparent), color(silver), color(silver), color(transparent));
    color: color(white);
    letter-spacing: rem(8px);
    font-size: fs(l);
    text-align: center;
    height: rem(35px);
    line-height: 1.4;
  }

  &--shipment {
    &::before {
      content: "NO DISPONIBLE";
      position: absolute;
      inset: 0;
      margin: auto;
      background: linear-gradient(90deg, color(transparent), color(silver), color(silver), color(transparent));
      color: color(white);
      letter-spacing: rem(8px);
      font-size: fs(m);
      text-align: center;
      height: rem(35px);
      line-height: 1.8;
    }
  }

  &::after {
    content: "";
    position: absolute;
    inset: 0;
    cursor: not-allowed;
  }
}