// Breakpoints
$breakpoints: (
  tn: 480px,
  sm: 750px,
  md: 1024px,
  lg: 1440px
);

$sizesPaddingMargin: (
  auto: auto,
  0: 0,
  1: rem(5px),
  2: rem(10px),
  3: rem(15px),
  4: rem(20px),
  5: rem(25px),
  6: rem(30px),
  7: rem(35px),
  8: rem(40px),
  9: rem(45px),
  10: rem(50px),
  11: rem(55px),
  12: rem(60px),
  13: rem(65px),
  14: rem(70px),
  15: rem(75px),
  16: rem(80px),
  17: rem(85px),
  18: rem(90px),
  19: rem(95px),
  20: rem(100px)
);

$sizesMaxWidth: (
  auto: auto,
  fc: fit-content,
  0: 100%,
  1: rem(40px),
  2: rem(80px),
  3: rem(120px),
  4: rem(160px),
  5: rem(200px),
  6: rem(240px),
  7: rem(280px),
  8: rem(320px),
  9: rem(360px),
  10: rem(400px),
  11: rem(440px),
  12: rem(480px),
  13: rem(520px),
  14: rem(560px),
  15: rem(600px),
  16: rem(640px),
  17: rem(680px),
  18: rem(720px),
  19: rem(760px),
  20: rem(800px),
  21: rem(840px),
  22: rem(880px),
  23: rem(920px),
  24: rem(960px),
  25: rem(1000px),
  26: rem(1040px)
);

$sizesGridColumns: (
  "1fr": 1fr,
  "2fr": 1fr 1fr,
  "3fr": 1fr 1fr 1fr,
  "4fr": 1fr 1fr 1fr 1fr,
  "5fr": 1fr 1fr 1fr 1fr 1fr,
  "6fr": 1fr 1fr 1fr 1fr 1fr 1fr,
  "1aut": auto,
  "2aut": auto auto,
  "3aut": auto auto auto,
  "4aut": auto auto auto auto,
  "5aut": auto auto auto auto auto,
  "6aut": auto auto auto auto auto auto,
  "1fa": 1fr,
  "2fa": 1fr auto,
  "3fa": 1fr auto 1fr,
  "4fa": 1fr auto 1fr auto,
  "5fa": 1fr auto 1fr auto 1fr,
  "6fa": 1fr auto 1fr auto 1fr auto,
  "1af": auto,
  "2af": auto 1fr,
  "3af": auto 1fr auto,
  "4af": auto 1fr auto 1fr,
  "5af": auto 1fr auto 1fr auto,
  "6af": auto 1fr auto 1fr auto 1fr
);

$sizesGap: (
  0: 0,
  1: rem(10px),
  2: rem(15px),
  3: rem(20px),
  4: rem(25px),
  5: rem(30px),
  6: rem(35px),
  7: rem(40px),
  8: rem(45px),
  9: rem(50px)
);

// Colors
$colors: (
  primary: #ec2a1a,
  secondary: #ffb84d,
  text-primary: #2d3748,
  text-secondary: #8f9bba,
  text-label: #2196f3,
  background: #efeff5,
  background-2: #ffffff,
  alter-background: rgba(#8080801a, 0.1),
  border-outline: #ffb84d,
  button-primary: #ff1640,
  yellow: #f3cc42,
  blue: #0a3861,
  green: #01a333,
  green-2: #15ab92,
  light-purple: #ebc8fc,
  silver: #808080,
  light-silver: #c7bfbf,
  cream: #fbbd87,
  black: #000000,
  light-black: #161515,
  white: #ffffff,
  purple: #bb549e,
  orange: #f47832,
  purple-2: #794df5,
  purple-3: #6b68fc,
  purple-4: #8916cf,
  red: #e4122e,
  red-whatermark: #d05f6e,
  whatermark: #03030311,
  transparent: transparent,
  black-rgba-1: rgba(#000, 0.1),
  black-rgba-5: rgba(#000, 0.5)
);

$fontSizes: (
  xs: rem(8px),
  s: rem(12px),
  c: rem(14px),
  n: rem(16px),
  m: rem(20px),
  l: rem(24px),
  xl: rem(28px),
  b: rem(32px),
  xb: rem(36px),
  h4: rem(46px),
  h3: rem(56px),
  h2: rem(66px),
  h1: rem(76px)
);

$borders: (
  none: none,
  xs: rem(0.25px) solid rgba(color(light-silver), 0.5),
  s: rem(0.5px) solid rgba(color(light-silver), 0.5),
  n: rem(1px) solid rgba(color(light-silver), 0.5),
  m: rem(2px) solid rgba(color(light-silver), 0.5),
  l: rem(3px) solid rgba(color(light-silver), 0.5),
  xl: rem(4px) solid rgba(color(light-silver), 0.5),
  b: rem(5px) solid rgba(color(light-silver), 0.5)
);

$bordersRadius: (
  none: initial,
  s: rem(5px),
  n: rem(10px),
  m: rem(15px),
  l: rem(20px),
  xl: rem(40px),
  b: rem(60px),
  circle: 100%
);

$weights: (
  100: 100,
  200: 200,
  300: 300,
  400: 400,
  500: 500,
  600: 600,
  700: 700,
  800: 800,
  900: 900
);

$fontTransform: (
  initial: initial,
  lower: lowercase,
  upper: uppercase,
  cap: capitalize
);

$transitions: (
  default: all 0.2s linear
);
