.slider {
  $this: &;
  position: relative;

  &--categories {
    .tns {
      &-nav {
        display: none !important;
      }
    }

    #{$this}__container {
      gap: rem(50px);
    }
  }

  &__link {
    border-radius: rem(5px);
    position: relative;
    text-align: center;
    color: color(text-secondary);
    background: linear-gradient(
        180deg,
        rgba(color(black), 0) 0%,
        rgba(color(orange), 0.55) 84.37%
      ),
      linear-gradient(
        180deg,
        rgba(color(black), 0) 0%,
        rgba(color(silver), 0.55) 84.37%
      ),
      color(light-purple);
    box-shadow: inset 0px 5px 10px color(silver),
      inset 0px 2px 0px rgba(color(purple), 0.25);
  }

  &__content {
    position: absolute;
    bottom: 0;
    right: 0;
    left: 0;
  }

  &__label {
    padding: rem(25px) rem(10px);
    word-break: break-word;
  }

  &--images {
    .tns-controls {
      display: none;
    }
  }

  &__image {
    cursor: grab;

    &:active {
      cursor: grabbing;
    }

    &:focus-visible {
      outline: none;
    }

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: center;

      &:focus-visible {
        outline: none;
      }

      @include qr(sm) {
        aspect-ratio: 5/2;
      }
    }

    &-container {
      img {
        display: flex;
        width: 100%;
        height: rem(450px);
        object-fit: cover;
        object-position: center;
        aspect-ratio: 5/3;
        opacity: 0.8;
        mix-blend-mode: multiply;
        transition: all 0.2s linear;

        &:hover {
          opacity: 1;
          mix-blend-mode: normal;
          transition: all 0.2s linear;
        }

        @include qr(sm) {
          height: rem(350px);
        }
      }
    }
  }

  &__container {
    display: flex;
  }

  &__nav-control {
    opacity: 0.3;
    border: none;
    cursor: pointer;
    transition: all 0.2s linear;

    &:hover {
      color: color(background);
      box-shadow: 2px 2px 2px 2px color(background);
      opacity: 0.5;
      transition: all 0.2s linear;
    }

    &--prev {
      position: absolute;
      top: 0;
      left: 0;
      display: flex;
      align-items: center;
      height: 100%;
      background: linear-gradient(
        90deg,
        color(black) 0%,
        color(background) 100%
      );
    }

    &--next {
      position: absolute;
      top: 0;
      right: 0;
      display: flex;
      align-items: center;
      height: 100%;
      background: linear-gradient(
        -90deg,
        color(black) 0%,
        color(background) 100%
      );
    }
  }
}

.tns {
  &-inner {
    overflow: hidden;
  }

  &-outer {
    button[data-action] {
      display: none;
    }
  }

  &-liveregion {
    display: none;
  }

  &-nav {
    position: absolute;
    left: 0;
    right: 0;
    bottom: rem(30px);
    display: flex !important;
    justify-content: center;
    margin-top: rem(15px);
    z-index: 1;

    button {
      visibility: hidden;

      &::before {
        content: "";
        cursor: pointer;
        visibility: visible;
        display: block;
        width: 10px;
        height: 10px;
        border-radius: br(n);
        background-color: color(background);
        border: b(s);

        @include qr(sm) {
          width: 15px;
          height: 15px;
        }
      }

      &:hover {
        &::before {
          box-shadow: 0 0 0 2px color(primary);
          border-color: color(background);
        }
      }
    }

    &-active {
      &::before {
        border-color: color(primary);
        box-shadow: 0 0 0 2px color(primary);
        background-color: color(background);
      }
    }
  }
}
