input,
textarea {
  border-radius: br(n);
  box-sizing: border-box;
  border: b(none);
  box-shadow: none;

  &:focus-visible {
    outline: none;
  }

  &:hover {
    box-shadow: 0 0 rem(2px) rem(0.5px) color(text-primary);
    transition: tst(default);
  }
}

.input {
  padding: rem(10px) rem(15px);
  color: color(text-primary);
  border: b(none);
  box-shadow: none;
  font-size: fs(n);
  background-color: color(background);
  transition: tst(default);

  &::placeholder {
    color: rgba(color(text-secondary), 0.5);
  }

  &:hover {
    border-radius: 0;
    box-shadow: 0 0 rem(2px) rem(0.5px) color(text-primary);
    transition: tst(default);
  }

  &--primary {
    position: relative;
    background-color: rgba(color(primary), 0.4);
    color: color(white);
    box-shadow: none;
    outline: rem(2px) solid rgba(color(primary), 1);
    transition: tst(default);

    &::placeholder {
      color: rgba(color(white), 0.5);
    }

    &:focus-visible {
      outline: rem(2px) solid rgba(color(light-black), 1);
    }

    &:hover {
      box-shadow: 0 rem(4px) rem(4px) 0 rgba(color(black), 0.9);
      transition: tst(default);
    }
  }

  &--outline {
    color: color(text-primary);
    background-color: color(transparent);
    border: b(none);
    outline: rem(1px) solid rgba(color(black), 0.2);
    transition: tst(default);

    &::placeholder {
      color: rgba(color(text-secondary), 0.8);
    }

    &:focus-visible {
      outline: rem(1px) solid rgba(color(light-black), 0.8);
    }

    &:hover {
      outline-color: rgba(color(light-black), 0.8);
      background-color: rgba(color(background), 0.8);
      box-shadow: 0 0 rem(2px) rem(0.5px) color(light-black);
      transition: tst(default);
    }
  }
}
