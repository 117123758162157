.profile {
  &__section {
    border-radius: br(n);
  }

  &-info {
    $this: &;

    &__inner {
      padding: rem(20px);
    }

    &__content {
      gap: rem(10px) rem(45px);

      @include qr(md) {
        gap: rem(30px) rem(45px);
      }
    }

    &__image {
      position: relative;
      max-width: rem(150px);
      width: 100%;
      height: min-content;
      border-radius: 100%;
      border: b(s);
      overflow: hidden;

      @include qr(sm) {
        max-width: rem(200px);
      }

      @include qr(md) {
        max-width: rem(250px);
      }

      &:hover {
        #{$this}__upload {
          opacity: 1;
          transition: all 0.2s linear;
        }
      }

      img {
        max-width: rem(200px);
        max-height: rem(180px);
        width: 100%;
        height: 100%;
        object-fit: cover;
        display: flex;
      }
    }

    &__upload {
      opacity: 0;
      position: absolute;
      top: 0;
      bottom: 0;
      right: 0;
      left: 0;
      color: color(white);
      display: flex;
      align-items: center;
      justify-content: center;
      font-weight: 600;
      cursor: pointer;
      background: rgba(color(black), 0.5);
      transition: all 0.2s linear;
    }
  }

  &-option {
    &__option {
      cursor: pointer;
      padding: rem(16px) rem(8px);
      transition: all 0.2s linear;

      &:hover {
        background: rgba(color(purple-3), 0.2);
        transition: all 0.2s linear;
      }

      &[data-selected="true"] {
        background: rgba(color(black), 0.8);

        .fs-n {
          color: color(white);
          letter-spacing: rem(5px);
          transition: tst(default);
        }
      }
    }
  }
}

.order {
  // &__section {
  //   &--bottom {
  //     border-top: rem(1px) solid color(silver);
  //   }
  // }

  // &__item {
  //   &-detail {
  //     cursor: pointer;
  //     width: 100%;
  //     // padding: rem(5px) 0;
  //     transition: all 0.2s linear;

  //     &:hover {
  //       padding: rem(5px);
  //       background-color: color(silver);
  //       transition: all 0.2s linear;
  //     }
  //   }
  // }

  &__content {
    &-title {
      border-bottom: b(n);
      padding: rem(10px) 0;
    }

    &-state {
      background-color: color(silver);
      display: flex;
      align-items: center;
      justify-content: center;
      width: rem(19px);
      height: rem(19px);
      border-radius: 100%;
      color: color(white);
    }
  }
  //ANTES
  // th {
  //   text-align: start;
  //   font-weight: normal;
  //   -webkit-text-stroke: rem(0.3px) color(black);
  // }
  // table {
  //   border-collapse: separate;
  //   border-spacing: rem(15px) rem(10px);
  //   width: 100%;
  // }

  th {
    font-weight: normal;
    -webkit-text-stroke: rem(0.3px) color(black);
  }
  tr {
    &:hover {
      padding: rem(5px);
      background-color: rgb(27, 145, 192);
      transition: all 0.2s linear;
      color:white
    }
  }

  table {
    border-collapse: collapse;
    border-spacing: rem(15px) rem(10px);
    width: 100%;
  }
}
