.detail {
  display: grid;
  grid-template-columns: auto;

  &-heading {
    padding: 0 pdd(4) (3);
    border-bottom: b(n);
    margin-bottom: rem(15px);
  }

  &-link {
    display: flex;
    padding: pdd(2);

    &:hover {
      background-color: color(alter-background);
    }
  }
}