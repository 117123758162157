.cart-notification {
  position: absolute;
  top: -150px;
  visibility: hidden;
  opacity: 0;
  right: rem(50px);
  box-shadow: 0 0 rem(10px) rem(2px) black;
  z-index: 100;
  transition: all 0.2s linear;
}
