.callout-text-and-products {
  &__inner {
    display: grid;
    grid-template-rows: auto 1fr;
    gap: rem(20px);
    box-shadow: 0 0 10px 2px rgba(color(black), 0.5);
    padding: rem(15px);

    @include qr(md) {
      grid-template-columns: 1fr 1fr;
      grid-template-rows: initial;
      gap: rem(60px);
      box-shadow: none;
      padding: 0;
    }
  }

  &__section {
    &--left {
      display: flex;
      flex-wrap: wrap;
      flex-direction: column;
      justify-content: center;
      gap: rem(25px);
      padding: rem(20px);

      .button {
        background: linear-gradient(
          97.05deg,
          color(purple-2) 11.55%,
          color(purple-3) 57.31%,
          color(purple-4) 103.57%
        );
      }
    }
  }

  &__text {
    font-size: rem(20px);
    opacity: 0.9;
  }

  &__button {
    display: flex;
  }
}

.gradient1 {
  background: radial-gradient(
      94.56% 94.56% at 79.13% 58.89%,
      rgba(color(yellow), 0.3) 0%,
      rgba(color(black), 0) 100%
    ),
    linear-gradient(
      180deg,
      rgba(color(primary), 0.4) 0%,
      rgba(color(purple), 0.5) 100%
    );
}

.gradient2 {
  background: linear-gradient(
      167.64deg,
      rgba(158, 221, 235, 0.7) 5.32%,
      rgba(228, 229, 251, 0.7) 39.44%,
      rgba(219, 250, 168, 0.7) 77.35%
    ),
    radial-gradient(
      87.68% 87.68% at 102.71% -5.61%,
      rgba(26, 221, 248, 0.7) 16.73%,
      rgba(77, 227, 173, 0.7) 89.58%
    );
}
