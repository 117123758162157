.loading {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  background-color: color(transparent);
  cursor: progress;
  pointer-events: none;
  z-index: 1;

  &__inner {
    margin: 0 auto;
    max-width: mw(1);
    animation: loading 1.5s normal infinite linear;
  }

  @keyframes loading {
    0% {
      transform: rotate(0);
    }
  
    100% {
      transform: rotate(360deg);
    }
  }
}


