img {
  width: 100%;
  object-fit: contain;
  max-height: 100%;
}

.img {
  overflow: hidden;

  &--center {
    object-position: center;
  }

  &--left {
    object-position: left;
  }

  &--right {
    object-position: right;
  }

  &--cover {
    object-fit: cover;
  }
}
