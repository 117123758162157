button {
  cursor: pointer;

  & > * {
    pointer-events: none;
  }
}

.button {
  padding: rem(10px) rem(15px);
  color: color(white);
  border-radius: br(n);
  font-size: fs(n);
  transition: tst(default);

  &:hover {
    border-radius: 0;
    transition: tst(default);
  }

  &--primary {
    position: relative;
    background-color: color(primary);
    border: none;
    box-shadow: 0 0 rem(2px) 0 rgba(color(black), 0.6);
    transition: tst(default);

    &:hover {
      transform: translateY(-4px);
      box-shadow: 0 rem(4px) rem(4px) 0 rgba(color(black), 0.9);
      transition: tst(default);
    }
  }

  &--outline {
    color: color(primary);
    background-color: transparent;
    border: b(n);
    border-color: color(primary);
    opacity: 0.7;
    transition: tst(default);

    &:hover {
      opacity: 1;
      transition: tst(default);
    }
  }
}

@media print {
  button,
  .button {
    display: none;
  }
}
