.shops {
  &__inner {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr ;
    gap: rem(30px);
    max-width: 100%;

    @include qr(tn,"max"){
        display: flex;
        gap: 9px;
        overflow-x: auto;
        overflow-y: hidden;
        scroll-snap-type: x mandatory;
        -ms-overflow-style: none;
        scrollbar-width: none;
        white-space: nowrap;
        scroll-behavior: smooth; 
      
    }
  }
}