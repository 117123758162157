.dropdown {
  $this: &;

  &__inner {
    display: flex;
    flex-direction: column;
    gap: rem(15px);
  }

  &__cta {
    position: relative;
    display: flex;
    align-items: center;
    background: rgba(color(text-primary), 0.2);

    &:hover {
      box-shadow: 0px 0px 5px 0.5px color(text-primary);
    }
  }

  &__button {
    position: relative;
    border: none;
    padding: pdd(2);
    text-align: start;
    width: 100%;
    cursor: pointer;
    background-color: initial;
    z-index: 1;
  }

  &__menu {
    display: none;
    flex-direction: column;
    padding: rem(10px) rem(5px);

    &-option {
      cursor: pointer;
      display: flex;

      &:hover {
        background-color: color(alter-background);
      }

      label {
        cursor: pointer;
        display: block;
        width: 100%;
        height: 100%;
        padding: rem(10px) 0;
      }
    }
  }

  &__icon {
    position: absolute;
    top: 0;
    right: 0;
  }

  &__option {
    div[data-open="true"] {
      & + #{$this}__menu {
        display: flex;
      }
    }
  }

  .hidden {
    display: none;
  }
}
