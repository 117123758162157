.join-us {
  &__heading {
    text-align: center;
  }

  &__card {
    &:nth-child(odd) {
      margin-bottom: rem(150px);
    }

    &:nth-child(even) {
      align-items: end;
    }

    &-container {
      border-radius: br(m);
      height: rem(250px);
    }
  }
}

.application-form {
  position: fixed;
  inset: 0;
  z-index: 20;
  background-color: rgba(color(black), 0.8);

  &::before {
    content: "";
    position: absolute;
    inset: 0;
  }

  &__close {
    position: absolute;
    top: 0;
    right: 0;
    background-color: color(transparent);
  }

  &__inner {
    max-width: rem(500px);
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
    box-sizing: border-box;
    padding: pdd(1);

    @include qr(sm) {
      padding: pdd(2);
    }
  }
}
