.search {
  width: 100%;
  position: relative;

  @include qr(tn,"max")
  {
    width: 100%;
    position: static;
  }

  &__input {
    width: 100%;
    border: none;
    border-top: b(s);
    border-bottom: b(s);
    border-left: b(s);
    border-radius: br(n) 0 0 br(n);
    padding: pdd(3);

    &:focus-visible {
      outline: none;
    }

    &-container {
      display: flex;
      justify-content: center;
      width: 100%;
      button{
          border: none;
          outline: none;
      }
 
    }
  }
  .icon {
    &--search {
      width: fit-content;
      padding: pdd(3);
      background-color: initial;
      cursor: pointer;

      &-1 {
        border-top: b(s);
        border-bottom: b(s);
        border-left: b(s);
        border-left: none;
        border-radius: 0 br(n) br(n) 0;
      }

      &-2 {
        border: b(s);
        // border-radius: br(m);
      }
    }
  }
  &__results {
    opacity: 1;
    visibility: visible;
    transition: opacity 0.3s ease, visibility 0.3s ease;
    width: 100%; 
    max-width: 56rem; 
    position: absolute;
     top: 5rem;
    z-index: 1;

    &.hide {
      opacity: 0;
      visibility: hidden;
    }
    
    
    .search__button-details:hover {
      background-color: red;
      color: white;
    }
    .search-result-item {
      display: grid;
      grid-template-columns: 1fr 1fr;
      border-radius: 8px;
      border: 2px solid #151513;
      cursor: pointer;
    }

    .search-result-item:hover{
      border: 2px solid #e90808;
    }


    .more-results {
      border: none;
      border-top: 2px solid #020202;
    }

    @media (max-width: 1366px) and (min-width: 1023px) {
      width: 44vw;
     
    }

    @media (max-width: 1024px) and (min-width: 767px) {
      width: 43vw;
     
    }

    @media (max-width: 768px) and (min-width: 481px) {
      width: 40vw;
     
    }

    

    @include qr(tn,"max") {
      top: 8.3rem;
      width: 95vw;

      .search__button-details:hover {
        background-color: red;
        color: white;
      }

      .ListResult {
        justify-content: center;
      }

      .search-result-item {
        display: grid;
        grid-template-columns: 0.5fr 0.5fr;
      }
    }

    
  }
}
