.popup {
  &-message {
    position: fixed;
    inset: 0;
    z-index: 20;
    background-color: rgba(color(black), 0.8);
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: fs(m);
    transition: tst(default);

    &__inner {
      background-color: color(background);
      padding: pdd(3);
      border-radius: br(s);
      width: 100%;
      max-width: rem(400px);
      animation: leap normal 0.2s linear forwards;
    }

    &__text {
      text-align: center;
      margin: mrg(4) 0;
      white-space: pre-line;
    }

    &__cta {
      display: flex;
      justify-content: space-around;
    }
  }
}

@keyframes leap {
  0% {
    transform: translateY(-250px);
  }

  100% {
    transform: translateY(0);
  }
}
