.progress-bar {
  border-radius: rem(25px);
  padding: rem(2px);

  &__inner {
    position: relative;
    overflow: hidden;
    max-width: 100%;
    border-radius: rem(25px) 0 0 rem(25px);

    &::before {
      content: "";
      position: absolute;
      top: -50px;
      bottom: -50px;
      width: 15%;
      background: linear-gradient(45deg, transparent 20%, color(white) 80%);
      transform: rotateZ(55deg);
      right: 0%;
      animation: bar-wait 1.5s infinite ease-in reverse;
    }

    &--complete {
      border-radius: rem(25px);
    }

    @keyframes bar-wait {
      0% {
        right: -50px;
      }

      100% {
        right: 100%;
      }
    }
  }
}
