.footer {
  display: flex;
  justify-content: center;
  background-color: color(primary);
  color: color(white);

  &__section {
    display: grid;
    grid-auto-flow: row;
    text-align: center;
    gap: rem(35px);

    @include qr(sm) {
      text-align: start;
      grid-auto-flow: column;
      justify-content: space-between;
      gap: rem(20px);
    }
  }

  &__copyright {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  &__payments {
    display: flex;
    max-height: rem(45px);
    justify-content: center;
  }

  .detail {
    &-heading {
      border-bottom: b(s);
      border-color: color(white);
      text-transform: uppercase;
    }

    ul {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-around;

      @include qr(sm) {
        display: block;
      }
    }
  }
}
