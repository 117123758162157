.select {
  border-radius: br(n);
  box-shadow: 0 0 rem(2px) rem(0.5px) color(silver);
  transition: tst(default);

  &:hover {
    box-shadow: 0 0 rem(2px) rem(0.5px) color(light-black);
    transition: tst(default);
  }

  &__inner {
    position: relative;
  }

  &__label {
    position: relative;
    padding: pdd(2);
    padding-right: rem(35px);
  }

  &__icon {
    position: absolute;
    top: 0;
    right: rem(5px);
    height: 100%;
    width: rem(25px);
    transition: tst(default);
  }

  [data-open="true"] {
    transform: rotateZ(-180deg);
    transition: tst(default);
  }

  &__options {
    position: absolute;
    color: color(text-primary);
    background-color: color(background);
    width: 100%;
    box-shadow: 0 0 rem(2px) rem(0.5px) color(light-black);
    max-height: rem(150px);
    overflow: auto;
    z-index: 10;
  }

  &__option {
    padding: pdd(1) pdd(2);

    &:hover {
      background-color: rgba(color(silver), 0.4);
    }
  }
}
