@use "sass:math";
$spacing: 0;

.d {
  display: block;

  &-flex {
    display: flex;
    flex-wrap: wrap;

    &__column {
      flex-direction: column;
      max-width: 100%;

      @for $i from 1 through 12 {
        &--#{$i} {
          flex-basis: math.percentage(math.div($i, 12)) - $spacing;
          max-width: math.percentage(math.div($i, 12)) - $spacing;
          
          @if $i == 12 {
            width: 100%;
          }
        }
      }

      @each $breakpoint, $bp in $breakpoints {
        @include qr($breakpoint) {
          @for $i from 1 through 12 {
            &--#{$i}\!#{$breakpoint} {
              flex-basis: math.percentage(math.div($i, 12));
              max-width: math.percentage(math.div($i, 12));
            }
          }
        }
      }
    }

    &--no-wrap {
      flex-wrap: nowrap;
    }

    &--column {
      flex-direction: column;
    }
  }

  // USE GRID ---
  &-grid {
    display: grid;

    &__column {
      grid-auto-columns: auto;

      @for $i from 1 through 6 {
        &--#{$i}fr {
          grid-template-columns: grid("#{$i}fr");
        }

        &--#{$i}aut {
          grid-template-columns: grid("#{$i}aut");
        }

        &--#{$i}fa {
          grid-template-columns: grid("#{$i}fa");
        }

        &--#{$i}af {
          grid-template-columns: grid("#{$i}af");
        }
      }

      @each $breakpoint, $bp in $breakpoints {
        @include qr($breakpoint) {
          @for $i from 1 through 6 {
            &--#{$i}fr\!#{$breakpoint} {
              grid-template-columns: grid("#{$i}fr");
            }

            &--#{$i}aut\!#{$breakpoint} {
              grid-template-columns: grid("#{$i}aut");
            }

            &--#{$i}fa\!#{$breakpoint} {
              grid-template-columns: grid("#{$i}fa");
            }

            &--#{$i}af\!#{$breakpoint} {
              grid-template-columns: grid("#{$i}af");
            }
          }
        }
      }
    }

    &__row {
      grid-auto-rows: auto;

      @for $i from 1 through 6 {
        &--#{$i}fr {
          grid-template-rows: grid("#{$i}fr");
        }

        &--#{$i}aut {
          grid-template-rows: grid("#{$i}aut");
        }

        &--#{$i}fa {
          grid-template-rows: grid("#{$i}fa");
        }

        &--#{$i}af {
          grid-template-rows: grid("#{$i}af");
        }
      }

      &--auto {
        grid-auto-rows: 1fr;
      }
    }
  }

  @each $size, $value in $sizesGap {
    &--gap-#{"" + $size} {
      gap: $value;
    }

    @each $breakpoint, $bp in $breakpoints {
      @include qr($breakpoint) {
        &--gap-#{"" + $size}\!#{$breakpoint} {
          gap: $value;
        }
      }
    }
  }

  &--start {
    align-items: self-start;
  }

  &--middle {
    align-items: center;
  }

  &--bottom {
    align-items: flex-end;
  }

  &--center {
    justify-content: center;
  }

  &--right {
    justify-content: flex-end;
  }

  &--between {
    justify-content: space-between;
  }

  &--around {
    justify-content: space-around;
  }

  &--evenly {
    justify-content: space-evenly;
  }
}
