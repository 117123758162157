.shop-container {
  @include qr(tn,"max")
  {
    padding: 0.625rem
  }
  .shop-profile {
    .info-container {
      position: relative;
      &__image-logo {
        position: absolute;
        bottom: 0;
        left: 0;
        object-fit:cover;
        @include qr(tn, "max") {
          width: 5rem;
        }
      }
      &__image-background{
        object-fit:cover;

      }
    }
  }

  .shop-profile__info {
    background: rgba(255, 255, 255, 0.8);
    font-size: 3rem;

    @media (max-width: 1024px) {
      font-size: 1.5rem;
    }

    @media (max-width: 480px) {
      font-size: 1.1rem;
    }
  }

  .shop-products-container {
    &__allProducts {
      .search-bar {
        input{
          @include qr(tn, "max") {
            width: 300px;
          }
        }
       
      }
    }

    &__container{
      @media (max-width: 480px)   {
        display: flex;
        justify-content: center;
      }
    }

    &__container ul{
      @media (min-width: 1440px)   {
        grid-template-columns: 1fr 1fr 1fr 1fr 1fr; 
      }

      @media (max-width: 1440px)   {
        grid-template-columns: 1fr 1fr 1fr 1fr; 
      }
      @media (max-width: 1024px)   {
        grid-template-columns: 1fr 1fr 1fr ; 
      }

      @media (max-width: 900px)   {
        grid-template-columns: 1fr 1fr  ; 
      }

      @media (max-width: 750px)   {
        grid-template-columns: 1fr 1fr ; 
      }
      @media (max-width: 480px)   {
        
       
        grid-template-columns: 1fr ; 
      }
      
    }

  }

  .shop-name {
    @media (max-width: 500px) {
      justify-content: center;
    }
  }

  .skeleton {
    background-color: #dcdcdc;
    border-radius: 0.3rem;
  }
}
