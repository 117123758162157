.hidden {
  display: none;
}

@each $breakpoint, $width in $breakpoints {
  @media (min-width: $width) {
    .hidden\!#{$breakpoint} {
      display: none;
    }
  }
}
