.login {
  &-menu {
    position: relative;

    &__image {
      position: absolute;
      z-index: 1;
      left: 0;
    }
  }
}
