.pos {
  &--relative {
    position: relative;
  }

  &--absolute {
    position: absolute;
  }

  &--inset {
    inset: 0;
  }

  &--top {
    top: 0;
  }

  &--bottom {
    bottom: 0;
  }

  &--left {
    left: 0;
  }

  &--right {
    right: 0;
  }
}
