/* animaciones del icono corazon */

.card--product{
  &__container{
    display: grid;
    grid-template-rows: 2.75rem 1fr auto;
    padding: 0.9375rem;
    border-radius: 8px;
    box-shadow: 1px 6px 10px 4px #d3d3d3;
  }
  
}

.product-button-outlines {
  &:hover {
    background-color: #e21d17;
    color: white;
    border: 3px solid #f7f3f3;
  }
}

.heart-icon {
  font-size: 24px;
  color: #ccc;
  cursor: pointer;
  transition: color 0.3s ease;

  &.favorite {
    color: red;
  }
}

.heart-button {
  cursor: pointer;
  transition: transform 0.2s ease-in-out;
}



.product-image-container {
  position: relative;

  &__discount-label {
    position: absolute;
    right: 0;
    z-index: 1;
  }
}


.card--product-container.product{
  cursor: pointer;
  img{
    transition: tst(default);
  }
}

.card--product-container.product:hover{
  img{
    transform: scale(1.1);
    transition: tst(default);
    transition: all 0.2s line
  } 
}

.card--product-container.description {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  overflow: hidden;
  -webkit-box-orient: vertical;
  -moz-box-orient: vertical;
}


.card--product-details-button {
  background-color: #fff;
  color: #fff;
  padding: 10px;
  border: none;
  cursor: pointer;
}

