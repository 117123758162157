.link {
  cursor: pointer;
  text-decoration: none;
  color: color(text-primary);
  padding: 1rem 0.75rem;
  border-radius: br(n);
  border: b(s);
  transition: tst(default);

  &:hover {
    transform: translateY(0.3rem);
    box-shadow: 0 -3px 5px 2px color(black);
    transition: all 0.2s linear;
  }

  &__container {
    display: flex;
    align-items: center;
    gap: 0.5rem;
  }

  &__icon {
    position: relative;

    &--cart {
      svg {
        color: color(text-secondary);
        fill: color(text-primary);
      }

      &-count {
        position: absolute;
        right: rem(-7px);
        bottom: rem(15px);
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: fs(s);
        background-color: color(white);
        padding: 0 rem(5px);
        border: b(s);
      }
    }
  }
}
