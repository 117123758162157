.carousel {
  &__container {
    position: relative;
  }

  &-default {
    &__nav-control {
      $this: &;
      position: absolute;
      top: 0;
      bottom: 0;
      border: none;
      opacity: 0.5;
      color: color(white);
      transition: tst(default);

      &:hover {
        opacity: 1;
        transition: tst(default);

        #{$this}--icon {
          transform: scale(1.2);
          transition: tst(default);
        }
      }

      &--prev {
        left: 0;
        background: linear-gradient(
          90deg,
          color(primary),
          color(transparent)
        );
      }

      &--next {
        right: 0;
        background: linear-gradient(
          90deg,
          color(transparent),
          rgba(color(primary), 0.3),
          color(primary)
        );
      }

      &--smallCircle {
        background:color(black);
        border-radius:50% ;
        top: 43%;
        bottom: 43%;
      }
    }
  }
}
