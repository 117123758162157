.eff {
  &-zoom {
    &--5 {
      transition: tst(default);

      &:hover {
        transform: scale(1.05);
        transition: tst(default);
      }
    }

    &--10 {
      transition: tst(default);

      &:hover {
        transform: scale(1.1);
        transition: tst(default);
      }
    }
  }

  &-color {
    &--primary {
      transition: tst(default);

      &:hover > * {
        color: color(primary);
        transition: tst(default);
      }
    }

    &--secondary {
      transition: tst(default);

      &:hover > * {
        color: color(secondary);
        transition: tst(default);
      }
    }
  }
}
