.spect {
  &__container {
    display: grid;
    grid-template-columns: 0.5fr 1fr;

    @media (max-width: 700px) {
      .spect__general {
        border: none;
      }

      text-align: center;
      grid-template-columns: 100%;
    }

    // Estilos para la tabla de especificaciones
    .spect__table {
      border-collapse: collapse;
      background-color: #f9f9f9;

      tr:nth-child(even),
      tr:nth-child(odd) {
        background-color: #f9f9f9;
      }

      td {
        padding: 10px;
        border: 1px solid #ddd;

        &:first-child {
          width: 60%;
          font-weight: bold;
        }
      }
    }

    // Estilos para la sección de información general
    .spect__general {
      border-left: 3px solid #00000029;
    }
  }
}
