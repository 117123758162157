.shop-card {
  &__inner {
    display: flex;
    flex-direction: column;
    gap: rem(15px);
    background-color: color(white);
  }

  &__logo {
    border-radius: 100%;
  }

  &__header {
    display: grid;
    grid-template-columns: auto 1fr;
    gap: rem(15px);

    .image {
      img {
        max-width: rem(100px);
      }
    }
  }

  &__content {
    display: flex;
    align-items: center;
  }

  &__products {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: rem(15px);
  }

  &__button {
    display: flex;
    justify-content: center;
  }
}
