.product {
    &__page {
      cursor: pointer;
      display: flex;
      justify-content: center;
      align-items: center;
      width: rem(25px);
      height: rem(25px);
      border-radius: rem(30px);
      background-color: rgba(color(black), 0.05);
  
      &:hover {
        background-color: rgba(color(black), 0.2);
      }
  
      &[data-current-page="true"] {
        color: color(white);
        background-color: color(black);
      }
    }
  
    &__nav {
      position: relative;
      cursor: pointer;
  
      &--unavailable {
        pointer-events: none;
        user-select: none;
        cursor: not-allowed;
      }
    }
}