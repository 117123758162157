.slide-next {
  animation: slideNext 0.5s forwards;
}

@keyframes slideNext {
  from {
    transform: translateX(-100%);
    opacity: 0;
  }
  to {
    transform: translateX(0);
    opacity: 1;
  }
}

.slide-back {
  animation: slideBack 0.5s forwards;
}

@keyframes slideBack {
  from {
    transform: translateX(100%);
    opacity: 0;
  }
  to {
    transform: translateX(0);
    opacity: 1;
  }
}


.home-promotions-text-and-products {
  @include qr(tn,"max")
  {
    padding: 0;
  }

  &__inner {
    display: grid;
    grid-template-rows: auto 1fr;
    gap: rem(20px);
    box-shadow: 0 0 10px 2px rgba(color(black), 0.5);
    padding: rem(15px);

    @include qr(md) {
      grid-template-columns:20rem 1fr;
      grid-template-rows: initial;
      gap: rem(30px);
      box-shadow: none;
      padding: 0;
    }

    @include qr(tn,"max") {
      display: block; /* Cambiar de grid a block */
        box-shadow: none;
      padding: 0;
    }

    article{
      

      @include qr(md,"max") {
      border: none;
      text-align: center;
      padding-top: 0.625rem;
      padding-bottom: 1.625rem;
      }
    }

    .slider-product-details{
      .slider-container {
        flex-wrap: nowrap;

        &__left {
          order: 1;
        }
        &__middle {
          order: 2;
        }
        &__right {
          order: 3;
        }

        @media (max-width: 480px) {

          flex-wrap: wrap;

          &__middle {
            order: 3;
          }
      
          &__right {
            order: 2;
          }
        }

      }
      
    }
  }
}