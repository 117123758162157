.product-provider {
  &__content {
    display: grid;
    grid-template-columns: rem(150px) 1fr;
    gap: rem(15px);

    @include qr(sm) {
      grid-template-columns: rem(250px) 1fr;
      gap: rem(30px);
    }
    @include qr(tn,"max"){
    position: relative; 
    grid-template-columns: auto;
    }
 
   .product-provider__filters {

  @include qr(tn,"max")
  {
    position: absolute; 
    top: 0; 
    left: 0; 
    width: 100%; 
    transition: transform 0.5s ease; 
    transform: translateX(-100%); 
    z-index: 2; 
  }
  
     
     .group-type{
       details {
         margin-bottom: 0.5rem;
         overflow: hidden;
         @include qr(tn,"max"){
          background: white;
          color: black;
         }
       }
       
       /* Estilos para el resumen */
       summary {
         display: block;
         background: #fffcfc00;
         position: relative;
         cursor: pointer;
         @include qr(tn,"max")
         {
          padding: 0.625rem;
         }
       }
       
       /* Estilos para el pseudo-elemento que simula el triángulo */
       summary::before {
         content: '';
         border-width: 0.4rem;
         border-style: solid;
         border-color: transparent transparent transparent #000000;
         position: absolute;
         top: 50%;
         right: 0;
         transform-origin: left center;
         transition: transform 0.25s ease;
         color:black;
         @include qr(tn,"max")
         {
          top: 28%;
          right: 26px;
         }
       }
       
       /* Estilos para el pseudo-elemento cuando el detalle está abierto */
       details[open] > summary::before {
         transform: translateY(-50%) rotate(90deg);
       }
       
       /* Desactivar el triángulo predeterminado en Chrome */
       summary::-webkit-details-marker {
         display: none;
       }
     }
 
     .filter-by-type{
       .price-slider-container{
         &__title{
           gap:0.152rem;
         }
         .custom-slider{
           .custom-slider__track{
             background: rgb(221, 180, 180) !important;
             @include qr(tn,"max")
             {
              background: #973d3d !important 
             }
           }
         }
 
       }
     }
   }

   .product-provider__filters.show {
    /* Estilos cuando los filtros están visibles */
    background: silver;
    transform: translateX(0%);
    margin: 0;
   
  }
    
  }

  &__cta {
    .button {
      &--outline {
        border-radius: rem(30px);

        &:hover {
          filter: none;
          border-radius: rem(30px);
          background-color: color(primary);
          color: color(white);
          transition: tst(default);
        }
      }
    }
  }
}
