.modal {
  position: fixed;
  inset: 0;
  background-color: rgba(color(black), 0.6);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 10;

  &__inner {
    position: relative;
    background-color: color(white);
    padding: pdd(2);
    padding-top: pdd(8);
  }

  &__content {
    overflow: none auto;
    max-height: 100%;

    &::-webkit-scrollbar {
      background-color: rgba(color(primary), 0.8);
      width: rem(5px);
    }

    &::-webkit-scrollbar-thumb {
      background-color: rgba(color(black), 0.5);
      border-radius: rem(15px);
    }
  }

  &__close {
    position: absolute;
    cursor: pointer;
    top: rem(10px);
    right: rem(10px);
  }
}
