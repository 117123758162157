.notification {
  position: absolute;
  top: rem(-150px);
  right: rem(50px);
  box-shadow: 0 0 rem(10px) rem(2px) black;
  background-color: color(black);
  color: color(white);
  z-index: 100;
  transition: tst(default);

  &__inner {
    display: flex;
    align-items: center;
    gap: rem(10px);
    padding: rem(15px) rem(20px);
  }

  &[data-trigger="true"] {
    top: 0;
  }
}
