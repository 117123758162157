.recent-products {
  $this: &;

  &__tile-inner {
    display: grid;
    grid-template-rows: rem(30px) 1fr rem(35px) auto;
    padding: pdd(3);
    gap: gap(2);
  }

  a {
    opacity: 0.7;

    &:hover {
      opacity: 1;

      #{$this}__product {
        &-title {
          color: color(primary);
        }
      }
    }
  }

  &__product {
    &-title {
      display: -webkit-box;
      -webkit-line-clamp: 2;
      overflow: hidden;
      -webkit-box-orient: vertical;
      -moz-box-orient: vertical;
      height: min-content;
    }
  }
}
