.links-categories {
  &__inner {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: rem(35px);
    padding: rem(30px);
  }

  &__link {
    display: block;
    width: fit-content;

    .button {
      &--primary {
        background-color: color(light-purple);
        color: color(text-primary);
      }
    }
  }
}
