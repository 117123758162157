.menu {
  display: flex;
}

.side-menu {
  $this: &;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
  overflow: hidden;
  background-color: #1212129e;

  &--link {
    display: flex;
  }

  &__container {
    position: absolute;
    top: 0;
    bottom: 0;
    max-width: 100%;
    width: 100%;
    max-height: 100%;
    overflow: auto;
    z-index: 2;
    background-color: white;
    transition: all 0.3s linear;

    @include qr(sm) {
      max-width: max-content;
    }
  }

  &__inner {
    padding: 2rem;
  }

  &__hamburger {
    #{$this}__container {
      max-height: max-content;
    }
  }
}
