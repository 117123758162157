.checkout {
  &__display-resume {
    display: none;
    @include qr(md, "max") {
      // position: absolute;
      height: 100%;
      z-index: 20;
      display: flex;
      align-items: center;

      .button {
        opacity: 1;
      }
    }
  }
}

/* Estilo para esquema de color claro */
@media (prefers-color-scheme: light) {
  .step-indicator {
    color: #000;
  }
}

/* Estilo para esquema de color oscuro */
@media (prefers-color-scheme: dark) {
  .step-indicator {
    color: #fff;
  }
}

.step-indicator::after {
  content: "";
  position: absolute;
  top: 50%;
  right: -41px;
  height: 1px;
  background-color: #000;
  width: 37px;
  border: 1px solid;
}

.step-indicator:last-child::after {
  display: none; /* Oculta el pseudo-elemento para el último indicador */
}

.step-indicator.active {
  background-color: #f3df2d; /* Color de fondo cuando está activo */
}

.step-indicator.complete {
  background-color: green; /* Color de fondo cuando está completo */
}

.chk {
  &-sections {
    &--2 {
      display: grid;
      gap: gap(5);

      @include qr(md) {
        grid-template-columns: 1fr rem(400px);
        
      }
       @include qr(sm,"min"){
        gap: gap(1);
       }
    }

  }

  &-product-tile {
    position: relative;

    &__number {
      // position: absolute;
      // top: rem(5px);
      // left: 0;
      width: rem(40px);
      height: rem(20px);
      background-color: rgba(color(black), 0.7);
      color: color(white);
      display: flex;
      align-items: center;
      justify-content: center;
    }

    &__offer {
      // position: absolute;
      // top: rem(5px);
      // right: 0;
      font-size: fs(s);
      // background-color: color(primary);
      background-color: #ff3d2dab;
      color: color(white);
      // padding: rem(5px) rem(10px);
      display: flex;
      align-items: center;
      justify-content: center;
      box-shadow: rem(2px) rem(2px) 0 0 color(black);
    }
  }

  &-content {
    @include qr(md, "max") {
      position: fixed;
      inset: 0;
      background-color: rgba(color(black), 0.8);
      display: flex;
      align-items: center;
      justify-content: center;
      z-index: 20;
      opacity: 0;
      visibility: hidden;
      transform: translateX(rem(-1400px));
      transition: tst(default);
    }

    &__close {
      display: none;

      @include qr(md, "max") {
        display: block;
        position: absolute;
        // top: rem(10px);
        right: rem(10px);
      }
    }

    &__inner {
      background-color: color(white);
      width: 100%;

      @include qr(md, "max") {
        margin: rem(10px);
        overflow-y: auto;
      }
    }
  }

  &-resume {
    &__inner {
      background-color: color(white);
      width: 100%;
      &--little{
        grid-template-columns: 0.9fr 5rem auto;
      }
    }
    &__number {
      display: flex;
      align-items: center;
      border-right: rem(1px) solid color(text-label);
      font-size: fs(s);
    }

    &__item {
      display: grid;
      grid-template-columns: rem(15px) 1fr rem(40px) rem(90px);
      margin-bottom: rem(10px);
      gap: rem(10px);
    }

    &__info {
      display: flex;
      flex-direction: column;
      overflow: hidden;
    }

    &__name {
      text-overflow: ellipsis;
      overflow: hidden;
      text-wrap: nowrap;
    }

    &__desc {
      text-overflow: ellipsis;
      overflow: hidden;
      text-wrap: nowrap;
      color: color(silver);
      font-size: fs(s);
    }

    &-offers {
      &__promotion {
        display: grid;
        grid-template-columns: 1fr rem(90px);
        margin-bottom: rem(10px);
        gap: rem(10px);
      }
    }
  }
}

.orders-table {
  width: 100%;
  border-collapse: collapse;
}

.orders-table th,
.orders-table td {
  border: 1px solid #ddd;
  padding: 8px;
}

.orders-table th {
  background-color: #f2f2f2;
  font-weight: bold;
}

.orders-table tr:nth-child(even) {
  background-color: #f2f2f2;
}

.orders-table td:first-child,
.orders-table th:first-child {
  width: 10%;
}

.orders-table td:nth-child(2),
.orders-table th:nth-child(2) {
  width: 54%;
}

.orders-table td:nth-child(3),
.orders-table th:nth-child(3) {
  width: 10%;
}

.orders-table td:nth-child(4),
.orders-table th:nth-child(4) {
  width: 13%;
}


