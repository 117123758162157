.product-detail {
  &__container {
    &__image {
      .detail-mosaic {
        img {
          cursor: pointer;
        }
      }

      .detail-mainImage {
        &.main-image {
          position: relative;
          transition: opacity 0.3s ease;
        }

        .overlay-message {
          position: relative;
          transform: translate(95%, -693%);
          background-color: rgba(0, 0, 0, 0.8); /* Fondo semi-transparente */
          opacity: 0;
          transition: opacity 0.3s ease;
          cursor: pointer;
        }
      }
      .detail-mainImage:hover .main-image {
        opacity: 0.7; /* Opacidad reducida al pasar el mouse sobre la imagen */
      }

      .detail-mainImage:hover .overlay-message {
        opacity: 1; /* Mostrar el mensaje al pasar el mouse sobre la imagen */
      }
      @media screen and (max-width: 750px) {
        .detail-mainImage:hover .main-image {
          opacity: 1; /* Opacidad reducida al pasar el mouse sobre la imagen */
        }
        .detail-mainImage .overlay-message {
          display: none; /* Ocultar el mensaje cuando el ancho de la imagen es menor o igual a 750px */
        }
      }
    }

    &__description {
      &--name {
        .product-details {
          @media (max-width: 480px) {
            grid-template-columns: 100%;
          }

          &__brand,
          &__name,
          &__price,
          &__rating,
          &__specifications,
          &__stock,
          &__description {
            @media (max-width: 480px) {
              text-align: center;
            }
          }

          &__rating {
            .star-rating {
              position: relative;
              &.star {
                &.filled {
                  color: #f1c40f;
                }
              }

              .star-icon {
                transition: color 0.3s ease, border 0.3s ease;
                cursor: pointer;

                &:hover {
                  color: #f1c40f;
                  transform: translateY(-5px);
                }
                &:hover ~ .star-icon {
                  color: #f1c40f; /* Al pasar el mouse sobre una estrella, pintamos todas las estrellas anteriores */
                }
              }
              .menssage-rating {
                display: none;
                position: absolute;
                top: -21px;
              }
            }

            @media (max-width: 480px) {
              justify-content: center;
            }
          }

          &__specifications {
            &__link {
              cursor: pointer;
            }
          }

          &__variants {
            cursor: pointer;
          }

          &__info {
            @media (max-width: 480px) {
              justify-content: center;
            }
          }

          &__description {
            a {
              cursor: pointer;
            }
          }
        }
      }
    }
  }
}
@media screen and (max-width: 479px) {
  .detail-mosaic {
    display: flex;
    justify-content: center;
    flex-wrap: nowrap;
    padding-top: 8px;
    width: 2.5rem;
  }

  .product-details {
    display: flex;
    flex-wrap: wrap;
  }
}

@media screen and (min-width: 478px) {
  .product-details {
    display: flex;
    flex-wrap: nowrap;
  }
}

/* Lista de tags */
.features-list-li {
  display: inline-block;
  background-color: #db3f34;
  color: #fff;
  cursor: pointer;
}

.features-color {
  margin: 5px 0 0 5px;
  content: "";
  display: inline-block;
  width: 16px;
  height: 16px;
  border-radius: 50%;
  margin-right: 5px;
}

/* Animaciones de desplazamiento */
.shop-slide-next {
  animation: slideNext 0.5s forwards;
}

@keyframes slideNext {
  from {
    transform: translateX(-100%);
    opacity: 0;
  }
  to {
    transform: translateX(0);
    opacity: 1;
  }
}

.shop-slide-back {
  animation: slideBack 0.5s forwards;
}

@keyframes slideBack {
  from {
    transform: translateX(100%);
    opacity: 0;
  }
  to {
    transform: translateX(0);
    opacity: 1;
  }
}

/* Enlaces destacados */
.link-destacado {
  font-weight: bold;
  transition: color 0.3s;
  cursor: pointer;

  &:hover {
    color: #ff6600;
  }
}

.descripcion-container{

  summary {
    list-style: none;
    margin-bottom: 3px; 
    transition: margin 150ms ease-out; 
  }
  details[open] summary {
    
    margin-bottom: 15px; 
  }

  summary::before {
    content: "+";
    font-size: 1.5rem;
  }
  
  details[open] summary::before {
    content: "-";
  }
}

.slider-product-details {
  &__litle {
    @media (max-width: 480px) {
      font-size: 1.4rem;
    }
  }
}

.product-detail-button-buy-now {
  border: 1px solid red; 
  cursor: pointer;

  @media (max-width: 480px) {
    height: 2.5rem;
  }

  &:hover {
    background-color: #fff;
    color: red;
    border: 1px solid red; 
  }
}


/* Botones de producto */
.product-detail-button-add-to-cart{
  border: 1px solid red; 
  cursor: pointer;
  font-size: 1rem;

  @media (max-width: 480px) {
    height: 2.5rem;
  }

  &:hover {
    background-color: #fff;
    color: red;
    border-radius: 0.625rem 
  }
}






//animacion del mensaje "gracias por tu calificacion"
@keyframes fadeOut {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

#thankYouMessage.fade-out {
  animation: fadeOut 2s ease;
}
