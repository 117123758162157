.header {
  position: fixed;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  border-bottom: b(xs);
  background-color: color(white);
  z-index: 10;

  .sections {
    padding: rem(16px) 0;
  }

  &__section {
    display: flex;
    align-items: center;
    gap: 0.5rem;
  }
}

.announcement-bar {
  $this: &;
  background-color: color(primary);

  &__inner {
    position: relative;
    padding: rem(15px) 0;
    color: color(white);
    text-align: center;
    padding-right: rem(40px);
  }

  &__close {
    position: absolute;
    cursor: pointer;
    top: 0;
    right: 0;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    opacity: 0.5;
    transition: all 0.2s linear;

    &:hover {
      opacity: 1;
      transition: all 0.2s linear;
    }
  }

  &-checkbox {
    display: none;

    &:checked {
      & + #{$this} {
        display: none;
      }
    }
  }
}

.header__container {
  position: relative;
  display: grid;
  gap: 0.30rem;
  grid-template-columns: auto 1fr auto;
  padding-top: 0.9375rem;
  padding-bottom: 0.50rem;
  z-index: 2;

  .header__section--left {
    order: 1;
  }
  .header__section--middle {
    order: 2;
  }
  .header__section--right {
    order: 3;
  }

  @media (max-width: 480px) {
    grid-template-columns: auto;

    .header__section--middle {
      order: 3;
    }

    .header__section--right {
      order: 2;
    }
  }
}

.header__section--right {
  z-index: 0;
}
