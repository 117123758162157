.navbar {
  $this: &;

  &__option {
    position: relative;
    display: flex;
    opacity: 0.9;
    transition: tst(default);

    @include qr(tn, "max") {
      display: block;
    }

    &:hover {
      opacity: 1;
      transition: tst(default);

      @include qr(tn) {
        #{$this} {
          &__categories {
            opacity: 1;
            visibility: visible;
            transition: tst(default);
          }
        }
      }

      .button {
        background-color: color(secondary);
        color: color(black);
      }
    }
  }

  &__categories {
    position: absolute;
    background-color: color(secondary);
    top: 100%;
    left: 0;
    right: 0;
    border-top: rem(1px) solid color(secondary);
    border-left: rem(1px) solid color(secondary);
    border-bottom: rem(1px) solid color(secondary);
    opacity: 0;
    visibility: hidden;
    z-index: 10;
    transition: tst(default);

    @include qr(tn, "max") {
      position: relative;
      inset: 0;
      display: none;

      &--visible {
        display: block;
        opacity: 1;
        visibility: visible;
      }
    }
  }

  &__category {
    display: flex;
    flex-direction: column;
    gap: rem(10px);
    padding: pdd(2);
    cursor: pointer;

    &--selected {
      background-color: color(white);
      font-weight: 700;
    }

    &:hover {
      background-color: color(white);
      font-weight: 700;
    }
  }

  &__subcategories {
    position: absolute;
    background-color: color(white);
    top: 100%;
    left: 100%;
    font-size: fs(c);
    border-top: rem(1px) solid color(secondary);
    border-right: rem(1px) solid color(secondary);
    border-bottom: rem(1px) solid color(secondary);
    transition: tst(default);

    @include qr(tn) {
      width: 100%;
    }

    @include qr(sm) {
      width: rem(400px);
      font-size: fs(n);
    }

    &-mobile {
      display: none;
      opacity: 0;
      visibility: hidden;

      &--visible {
        display: block;
        opacity: 1;
        visibility: visible;
      }
    }

    &--hidden {
      opacity: 0;
      visibility: hidden;
      transition: tst(default);
    }
  }

  &__subcategory {
    width: 100%;
    padding: pdd(3);
    padding-right: rem(40px);
    margin: 0;
    cursor: pointer;

    &:hover {
      background-color: rgba(color(secondary), 0.4);
      font-weight: 700;

      &::before {
        content: "";
        position: absolute;
        right: rem(10px);
        height: rem(30px);
        width: rem(30px);
        background-position: center rem(7px);
        background-size: rem(30px);
        background-repeat: no-repeat;
        background-image: url("../../assets/aqupe-logo.svg");

        @include qr(tn, "max") {
          right: rem(20px);
        }
      }
    }
  }
}
