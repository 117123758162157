@import "./variables.scss";

* {
  box-sizing: inherit;
}

iframe#webpack-dev-server-client-overlay {
  display: none !important;
}

html {
  box-sizing: border-box;
  text-rendering: optimizeLegibility;
  font-size: fs(n);
  background-color: color(background);
  font-family: "Trebuchet MS", "Lucida Sans Unicode", "Lucida Grande",
    "Lucida Sans", Arial, sans-serif;
}

body {
  opacity: 0;
  margin: 0;
  padding: 0;
  border: none;
  animation: appear normal 1s linear forwards;
}

#root {
  display: flex;
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  overflow: hidden;
  flex-direction: column;
}

.container {
  max-width: rem(1800px);
  width: 100%;
  height: 100%;
  margin: 0 auto;

  &--inner {
    max-width: 100%;
  }
}

.wrapper {
  padding: 0 rem(10px);

  @include qr(sm) {
    padding: 0 rem(20px);
  }

  @include qr(md) {
    padding: 0 rem(30px);
  }

  @include qr(lg) {
    padding: 0 rem(40px);
  }
}

.content {
  height: 100vh;
  overflow: auto;
}

ul {
  padding: 0;
  margin: 0;
}

li {
  list-style: none;
}

a {
  text-decoration: none;
  color: inherit;
}

.bkg {
  @each $color, $value in $colors {
    &-#{"" + $color} {
      background-color: $value;
    }

    @each $breakpoint, $bp in $breakpoints {
      @include qr($breakpoint) {
        &-#{"" + $color}\!#{$breakpoint} {
          background-color: $value;
        }
      }
    }
  }
}

.color {
  @each $color, $value in $colors {
    &-#{"" + $color} {
      color: $value;
    }
  }
}

.pdd {
  @each $padding, $value in $sizesPaddingMargin {
    &-#{"" + $padding} {
      padding: $value;
    }

    &-v-#{"" + $padding} {
      padding-top: $value;
      padding-bottom: $value;
    }

    &-h-#{"" + $padding} {
      padding-left: $value;
      padding-right: $value;
    }

    &-t-#{"" + $padding} {
      padding-top: $value;
    }

    &-b-#{"" + $padding} {
      padding-bottom: $value;
    }

    &-r-#{"" + $padding} {
      padding-right: $value;
    }

    &-l-#{"" + $padding} {
      padding-left: $value;
    }

    @each $breakpoint, $bp in $breakpoints {
      @include qr($breakpoint) {
        &-#{"" + $padding}\!#{$breakpoint} {
          padding: $value;
        }

        &-v-#{"" + $padding}\!#{$breakpoint} {
          padding-top: $value;
          padding-bottom: $value;
        }

        &-h-#{"" + $padding}\!#{$breakpoint} {
          padding-left: $value;
          padding-right: $value;
        }

        &-t-#{"" + $padding}\!#{$breakpoint} {
          padding-top: $value;
        }

        &-b-#{"" + $padding}\!#{$breakpoint} {
          padding-bottom: $value;
        }

        &-r-#{"" + $padding}\!#{$breakpoint} {
          padding-right: $value;
        }

        &-l-#{"" + $padding}\!#{$breakpoint} {
          padding-left: $value;
        }
      }
    }
  }
}

.mrg {
  @each $margin, $value in $sizesPaddingMargin {
    &-#{"" + $margin} {
      margin: $value;
    }

    &-v-#{"" + $margin} {
      margin-top: $value;
      margin-bottom: $value;
    }

    &-h-#{"" + $margin} {
      margin-left: $value;
      margin-right: $value;
    }

    &-t-#{"" + $margin} {
      margin-top: $value;
    }

    &-b-#{"" + $margin} {
      margin-bottom: $value;
    }

    &-r-#{"" + $margin} {
      margin-right: $value;
    }

    &-l-#{"" + $margin} {
      margin-left: $value;
    }

    @each $breakpoint, $bp in $breakpoints {
      @include qr($breakpoint) {
        &-#{"" + $margin}\!#{$breakpoint} {
          margin: $value;
        }

        &-v-#{"" + $margin}\!#{$breakpoint} {
          margin-top: $value;
          margin-bottom: $value;
        }

        &-h-#{"" + $margin}\!#{$breakpoint} {
          margin-left: $value;
          margin-right: $value;
        }

        &-t-#{"" + $margin}\!#{$breakpoint} {
          margin-top: $value;
        }

        &-b-#{"" + $margin}\!#{$breakpoint} {
          margin-bottom: $value;
        }

        &-r-#{"" + $margin}\!#{$breakpoint} {
          margin-right: $value;
        }

        &-l-#{"" + $margin}\!#{$breakpoint} {
          margin-left: $value;
        }
      }
    }
  }
}

.mw {
  @each $maxWidth, $value in $sizesMaxWidth {
    &-#{"" + $maxWidth} {
      max-width: $value;
    }

    @each $breakpoint, $bp in $breakpoints {
      @include qr($breakpoint) {
        &-#{"" + $maxWidth}\!#{$breakpoint} {
          max-width: $value;
        }
      }
    }
  }
}

.mh {
  @each $maxWidth, $value in $sizesMaxWidth {
    &-#{"" + $maxWidth} {
      max-height: $value;
    }

    @each $breakpoint, $bp in $breakpoints {
      @include qr($breakpoint) {
        &-#{"" + $maxWidth}\!#{$breakpoint} {
          max-height: $value;
        }
      }
    }
  }
}

.w {
  @each $width, $value in $sizesMaxWidth {
    &-#{"" + $width} {
      width: $value;
    }

    @each $breakpoint, $bp in $breakpoints {
      @include qr($breakpoint) {
        &-#{"" + $width}\!#{$breakpoint} {
          width: $value;
        }
      }
    }
  }
}

.h {
  @each $width, $value in $sizesMaxWidth {
    &-#{"" + $width} {
      height: $value;
    }

    @each $breakpoint, $bp in $breakpoints {
      @include qr($breakpoint) {
        &-#{"" + $width}\!#{$breakpoint} {
          height: $value;
        }
      }
    }
  }
}

.b {
  @each $border, $value in $borders {
    &-#{"" + $border} {
      border: $value;
    }

    &-t-#{"" + $border} {
      border-top: $value;
    }

    &-b-#{"" + $border} {
      border-bottom: $value;
    }

    &-r-#{"" + $border} {
      border-right: $value;
    }

    &-l-#{"" + $border} {
      border-left: $value;
    }
  }

  &r {
    @each $borderR, $value in $bordersRadius {
      &-#{"" + $borderR} {
        border-radius: $value;
      }
    }
  }

  &c {
    @each $color, $value in $colors {
      &-#{"" + $color} {
        border-color: $value;
      }
    }
  }
}

.f {
  &s {
    @each $size, $value in $fontSizes {
      &-#{"" + $size} {
        font-size: $value;
      }

      @each $breakpoint, $bp in $breakpoints {
        @include qr($breakpoint) {
          &-#{"" + $size}\!#{$breakpoint} {
            font-size: $value;
          }
        }
      }
    }
  }

  &w {
    @each $weight, $value in $weights {
      &-#{"" + $weight} {
        font-weight: $value;
      }

      @each $breakpoint, $bp in $breakpoints {
        @include qr($breakpoint) {
          &-#{"" + $weight}\!#{$breakpoint} {
            font-size: $value;
          }
        }
      }
    }
  }

  &t {
    @each $transform, $value in $fontTransform {
      &-#{"" + $transform} {
        text-transform: $value;
      }

      @each $breakpoint, $bp in $breakpoints {
        @include qr($breakpoint) {
          &-#{"" + $transform}\!#{$breakpoint} {
            font-size: $value;
          }
        }
      }
    }
  }
}

.t {
  &a {
    &-right {
      text-align: right;
    }

    &-left {
      text-align: left;
    }

    &-center {
      text-align: center;
    }
  }

  &d {
    &-underline {
      text-decoration: underline;
    }

    &-through {
      text-decoration: line-through;
    }
  }
}

.list {
  &-inside {
    list-style: inside;
  }

  &-circle {
    list-style-type: circle;
  }

  &-square {
    list-style: square;
  }
}

// Class of Component
.lazy-load-image-background {
  display: flex !important;
  height: 100%;
}

@keyframes appear {
  0% {
    opacity: 0;
  }

  25% {
    opacity: 0;
  }

  35% {
    opacity: 0.1;
  }

  50% {
    opacity: 0.3;
  }

  75% {
    opacity: 0.75;
  }

  100% {
    opacity: 1;
  }
}
