
.custom-slider {
  &__container {
    width: 80%; 
    margin: 20px auto; 
  }

  
  &__thumb {
    top: -4px;
    width: 40px;
    background-color: #2196F3; 
    border: 2px solid #1565C0; 
    cursor: grab; 
  }

  
  &__track {
    height: 10px;
    background: linear-gradient(to right, #FF8F00, #FFC107); 
    border-radius: 5px; 
  }
}
