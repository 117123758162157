.section-text-card {
  position: relative;
  margin-top: calc(rem(110px) * -1);
  z-index: 0;
}

.text-card {
  &__container {
    display: grid;
    grid-auto-rows: 1fr;
    gap: rem(20px);
    text-align: center;
    padding: 0 rem(40px);

    @include qr(sm) {
      grid-template-columns: 1fr 1fr 1fr;
      justify-content: center;
    }

    @include qr(md) {
      padding: 0 rem(80px);
    }
  }

  &__icon {
    display: flex;
    justify-content: center;

    svg {
      fill: none;
    }
  }

  &__heading {
    text-transform: uppercase;
  }

  &__item {
    display: grid;
    grid-template-rows: auto 1fr auto;
    gap: rem(20px);
    padding: rem(10px) rem(20px);
    background-color: color(yellow);

    @include qr(sm) {
      padding: rem(20px) rem(30px);
    }
  }

  &__content {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
