.catalogue {
  &__inner {
    display: grid;
    grid-template-columns: rem(400px) 1fr;
    gap: rem(20px);
  }

  &__section {
    &--left {
      padding: rem(15px);
      background-color: color(white);
    }

    &--right {
      padding: rem(15px);
      background-color: color(white);
    }
  }
}

.content {
  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &__sort {
    .button {
      font-size: rem(18px);
      color: color(text-primary);
      text-transform: uppercase;

      &--primary {
        background-color: color(yellow);
      }
    }
  }
}

.pagination {
  &__inner {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: rem(15px);
  }

  &__button {
    display: flex;

    a {
      &:hover {
        background: color(black);
        color: color(text-secondary);
      }
    }
  }
}

.pages {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: rem(10px);
  width: 100%;
  height: 100%;
}

.page {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 25px;
  height: 25px;
  cursor: pointer;
  border-radius: rem(5px);

  &:hover {
    background: color(black);
    color: color(text-secondary);
  }
}

.filter {
  display: flex;
  flex-direction: column;
  gap: rem(15px);
}
