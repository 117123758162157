.section-block {
  &-heading {
    &__header {
      display: grid;
      grid-template-columns: 1fr auto;
      gap: rem(20px);
      padding: rem(30px) 0;

      &--link {
        &:hover {
          color: color(primary);
        }
      }
    }

    &__inner {
      padding: rem(15px);
    }
  }
}
