.product-card,
.product-card-v2 {
  $this: &;
  position: relative;
  display: inherit;

  &__inner {
    max-width: 100%;
    background-color: color(white);
    padding: rem(10px);
    border-radius: br(n);
    border: b(xs);

    &--1 {
      display: grid;
      grid-template-rows: 1fr auto;
      gap: rem(20px);
    }

    &--2 {
      display: flex;
    }
  }

  &__product {
    width: 100%;
  }

  &__link {
    position: relative;
    display: grid;
    grid-template-rows: rem(180px) 1fr;
    gap: rem(20px);

    @include qr(sm) {
      grid-template-rows: rem(150px) 1fr;
    }

    @include qr(md) {
      grid-template-rows: rem(200px) 1fr;
    }

    @include qr(lg) {
      grid-template-rows: rem(350px) 1fr;
    }

    &:hover {
      img {
        transform: scale(1.1);
        transition: tst(default);
      }

      #{$this}__content {
        color: color(primary);
        transition: tst(default);
      }
    }
  }

  &__discount {
    background-color: color(primary);
    border-radius: br(m);
    color: color(text-secondary);
    width: rem(40px);
    height: rem(40px);
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__image {
    &-container {
      display: flex;
      justify-content: center;
      border-radius: br(n);
      padding: rem(15px);
    }

    img {
      width: 100%;
      height: 100%;
      min-height: rem(50px);
      object-fit: contain;
      transition: tst(default);
    }
  }

  &__heading {
    text-align: center;
  }

  &__content {
    display: flex;
    flex-direction: column;
    gap: rem(10px);
    justify-content: space-between;
    transition: tst(default);
  }

  &__info {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-around;
  }

  &__score {
    display: flex;
    align-items: center;
    gap: rem(5px);
    color: color(silver);

    svg {
      stroke: color(yellow);
      color: color(yellow);
      width: rem(20px);
    }
  }

  &__reviews {
    display: flex;
    align-items: center;
    gap: rem(5px);
  }

  &__prices {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    gap: rem(10px);
    font-size: fs(s);

    @include qr(sm) {
      font-size: fs(n);
    }
  }

  &__price {
    &--old {
      color: color(silver);
      text-decoration: line-through;
    }

    &--new {
      color: color(primary);
    }
  }

  &__buttons {
    display: grid;
    grid-template-columns: 1fr rem(38px);
    gap: rem(10px);

    @include qr(sm) {
      gap: rem(20px);
    }
  }

  &__button {
    &--add-to-favorites {
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: color(yellow);
      border-radius: br(n);
      transition: all 0.2s linear;

      &:hover {
        box-shadow: 0 0 4px 6px color(silver);
        transform: scale(0.95);
        transition: all 0.2s linear;
      }

      button {
        background-color: transparent;
        border: none;
      }

      svg {
        user-select: none;
      }
    }
  }

  .icon {
    &--heart {
      svg {
        width: rem(20px);
        color: color(primary);
      }
    }
  }

  .button {
    width: 100%;
  }

  &__remove {
    position: absolute;
    left: rem(10px);
    bottom: rem(10px);
    cursor: pointer;
    transition: all 0.2s linear;

    &:hover {
      color: red;
      transform: scale(1.1);
      transition: all 0.2s linear;
    }
  }
}
