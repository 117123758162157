@use "sass:math";

@mixin qr($breakpoint,  $direction: '') {
  @if map-has-key($breakpoints, $breakpoint) {
    // Get the breakpoint value from the map.
    $breakpoint-value: map-get($breakpoints, $breakpoint);

    // Check the query direction: default is min (mobile first approach).
    @if $direction == "" or $direction == min {
      @media (min-width: $breakpoint-value) {
        @content;
      }
    } @else if $direction == max {
      @media (max-width: ($breakpoint-value - 1)) {
        @content;
      }
    } @else {
      @media ($direction: $breakpoint-value) {
        @content;
      }
    }

    // If the breakpoint doesn't exist in the map, we can use other, custom values.
  } @else {
    @if $direction == "" or $direction == min {
      @media (min-width: $breakpoint) {
        @content;
      }
    } @else if $direction == max {
      @media (max-width: $breakpoint) {
        @content;
      }
    } @else {
      @media ($direction: $breakpoint) {
        @content;
      }
    }
  }
}

@function rem($px) {
  @return math.div($px, 16px) * 1rem;
}

@function color($name) {
  @if map-has-key($colors, $name) {
    @return map-get($colors, $name);
  } @else {
    @error "ERROR: color not found";
  }
}

@function mw($n) {
  @if map-has-key($sizesMaxWidth, $n) {
    @return map-get($sizesMaxWidth, $n);
  } @else {
    @error "ERROR: size not found";
  }
}

@function pdd($n) {
  @if map-has-key($sizesPaddingMargin, $n) {
    @return map-get($sizesPaddingMargin, $n);
  } @else {
    @error "ERROR: size not found";
  }
}

@function mrg($n) {
  @if map-has-key($sizesPaddingMargin, $n) {
    @return map-get($sizesPaddingMargin, $n);
  } @else {
    @error "ERROR: size not found";
  }
}

@function grid($n) {
  @if map-has-key($sizesGridColumns, $n) {
    @return map-get($sizesGridColumns, $n);
  } @else {
    @error "ERROR: size not found";
  }
}

@function gap($gap) {
  @if map-has-key($sizesGap, $gap) {
    @return map-get($sizesGap, $gap);
  } @else {
    @error "ERROR: gap not found";
  }
}

@function fs($size) {
  @if map-has-key($fontSizes, $size) {
    @return map-get($fontSizes, $size);
  } @else {
    @error "ERROR: font size not found";
  }
}

@function ft($transform) {
  @if map-has-key($fontTransform, $transform) {
    @return map-get($fontTransform, $transform);
  } @else {
    @error "ERROR: Weight not found";
  }
}

@function b($border) {
  @if map-has-key($borders, $border) {
    @return map-get($borders, $border);
  } @else {
    @error "ERROR: Border not found";
  }
}

@function br($borderR) {
  @if map-has-key($bordersRadius, $borderR) {
    @return map-get($bordersRadius, $borderR);
  } @else {
    @error "ERROR: Border radius not found";
  }
}

@function bc($name) {
  @if map-has-key($colors, $name) {
    @return map-get($colors, $name);
  } @else {
    @error "ERROR: color not found";
  }
}

@function fw($weight) {
  @if map-has-key($weights, $weight) {
    @return map-get($weights, $weight);
  } @else {
    @error "ERROR: Weight not found";
  }
}

@function tst($transition) {
  @if map-has-key($transitions, $transition) {
    @return map-get($transitions, $transition);
  } @else {
    @error "ERROR: Weight not found";
  }
}
