svg {
  display: block;
  width: rem(18px);
  height: rem(18px);

  @include qr(sm) {
    width: rem(21px);
    height: rem(21px);
  }
}
